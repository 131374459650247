import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

import './layout.css?v=005'
import './theme_hockey.css?v=002'

const LayoutHockey = ({ children }) => {
  const data = useStaticQuery(graphql`
    query HockeySiteTitleQuery {  
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
      <>
        <Helmet title={data.site.siteMetadata.title} >
          <html lang="en" />
          <body className="hockey" />
        </Helmet>

        {children}
      </>
  )
}

LayoutHockey.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutHockey
